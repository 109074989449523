<template>
  <div>
    <b-row
      v-if="showheader"
      class="m-1">
      <b-col
        cols="6"
        class="d-flex">
        <h2 class="mb-1 text-primary">- {{ title }}</h2>
      </b-col>
      <b-col
        cols="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-end">
        <b-button
          id="add-button"
          class=""
          variant="primary"
          :to="{
            name: 'apps-labtest-create',
            params: { case_id: case_id, type: type },
          }">
          {{ $t(RESOURCES_ELEMENT_NAME + '.actions.create') }}
        </b-button>
      </b-col>
    </b-row>

    <b-table
      ref="refElementsListTable"
      class="position-relative"
      :items="items"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      :empty-text="$t(RESOURCES_ELEMENT_NAME + '.search.not_found')">
      <!-- Column: key -->
      <template
        v-if="type == 'FOLLOWUP'"
        #cell(next_consult)="data">
        {{ getNextConsult(data.item) }}
      </template>
      <template #cell(request_destination)="data">
        <!-- Si hay órdenes médicas -->
        <div v-if="data.item.case && data.item.case.medical_orders && data.item.case.medical_orders.length">
          <!-- Iterar sobre cada orden médica y alinear con auth_number -->
          <div v-for="(order, index) in data.item.case.medical_orders" :key="index">
            <span v-if="getAuthNumber(data.item.auth_number) === order.id.toString()">
              <span v-if="order.request_destination_id === 1780">
                Trabajador
              </span>
              <span v-else-if="order.request_destination_id === 1781">
                Fuente: {{ data.item.case.source_first_name }} {{ data.item.case.source_first_surname }}
              </span>
              <span v-else>
                No identificado
              </span>
            </span>
          </div>
        </div>
        <!-- Si no hay órdenes médicas -->
        <span v-else>
          No hay órdenes médicas
        </span>
      </template>
      <template #cell(name)="data">
        {{ data.item.name ? data.item.name : data.item.report.name }}
      </template>
      <template #cell(date)="data">
        {{ formatDate(data.item.date) }}
      </template>
      <template #cell(result)="data">
        {{ data.item.result ? data.item.result.value : '' }}
      </template>
      <template #cell(provider)="data">
        {{ data.item.provider ? data.item.provider.name : '' }}
      </template>
      <template #cell(made_by_avenir)="data">
        <span v-if="data.item.made_by_avenir"> {{ $t('boolean.yes') }}</span>
        <span v-else> {{ $t('boolean.no') }} </span>
      </template>
      <template #cell(report)="data">
        <div v-if="data.item.report">
          <a
            id="view-report-button"
            class="btn mb-1 mb-sm-0 mr-0 mr-sm-1 btn-secondary"
            :href="getUrl(data.item.report)"
            target="_blank">
            {{ $t(RESOURCES_ELEMENT_NAME + '.download') }}
          </a>
          <!-- ? VIEW -->
          <b-tooltip
            target="view-report-button"
            :title="$t(RESOURCES_ELEMENT_NAME + '.download')"
            triggers="hover"
            :noninteractive="true"
            fallback-placement="counterclockwise">
          </b-tooltip>
        </div>
      </template>
      <!-- Column: Status -->
      <!-- Column: actions -->
      <template #cell(actions)="data">
        <b-col class="flex flex-row flex-wrap">
          <b-button
            :id="`view-button-${data.item.id}`"
            style="margin: 1px"
            variant="primary"
            :to="{
              name: 'apps-labtest-view',
              params: { case_id: case_id, id: data.item.id },
            }">
            <feather-icon icon="FileTextIcon" />
          </b-button>

          <b-button
            :id="`edit-button-${data.item.id}`"
            style="margin: 1px"
            variant="primary"
            :to="{
              name: 'apps-labtest-edit',
              params: { case_id: case_id, id: data.item.id },
            }">
            <feather-icon icon="EditIcon" />
          </b-button>
          <!-- ? VIEW -->
          <b-tooltip
            :target="`view-button-${data.item.id}`"
            :title="$t('actions.details')"
            triggers="hover"
            :noninteractive="true"
            fallback-placement="counterclockwise">
          </b-tooltip>
          <!-- ? EDIT -->
          <b-tooltip
            :target="`edit-button-${data.item.id}`"
            :title="$t('actions.edit')"
            triggers="hover"
            :noninteractive="true"
            fallback-placement="counterclockwise">
          </b-tooltip>
        </b-col>
      </template>
    </b-table>
    <!-- Pagination -->
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="total"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item">
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18" />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment'
import store from '@/store'
import router from '@/router'
import ElementStoreModule from '@/views/case/labtests/storeModule'
import { numeric } from 'vee-validate/dist/rules'

export default {
  components: {
    BCardBody,
    BTooltip,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: undefined,
    },
    showheader: {
      type: Boolean,
      default: true,
    },
    showheader: {
      type: Boolean,
      default: true,
    },
    followtype: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      items: [],
      total: 1,
      perPage: 5,
      currentPage: 1,
      query: '',
      selected_element: {},
      currentSelection: {},
      patient_id: undefined,
      case_id: undefined,
      patient_identity_type: '',
      patient_identity: '',
      patient_name: '',
      tableColumns: [],
      case: undefined,
    }
  },
  watch: {
    perPage(val) {
      this.fetchElements(this.type)
    },
    currentPage(val) {
      this.fetchElements(this.type)
    },
    query(val) {
      this.currentPage = 1
      this.fetchElements(this.type)
    },
  },
  async mounted() {
    this.RESOURCES_ELEMENT_NAME = 'labtests'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-mh-v-${this.RESOURCES_ELEMENT_NAME}`
    this.patient_id = router.currentRoute.params.patient_id
    this.case_id = router.currentRoute.params.case_id

    this.tableColumns = [
      {
        key: 'name',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.name`),
        sortable: true,
      },
      {
        key: 'date',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.date`),
        sortable: true,
      },
      {
        key: 'result',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.result`),
        sortable: true,
      },
      {
        key: 'provider',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.provider`),
        sortable: true,
      },
      {
        key: 'made_by_avenir',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.made_by_avenir`),
        sortable: true,
      },
      {
        key: 'report',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.report`),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.actions`),
        thStyle: 'width: 300px;',
      },
    ]

    if (this.type == 'FOLLOWUP') {
      this.tableColumns.splice(0, 0, {
        key: 'auth_number',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.auth_number`),
        sortable: true,
      })
    }

    if (this.type == 'FOLLOWUP') {
      this.tableColumns.splice(0, 0, {
        key: 'next_consult',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.follow_type`),
        sortable: true,
        thStyle: 'display: none;',
        tdClass: 'd-none',  
      })
    }

    if (this.type == 'FOLLOWUP') {
      this.tableColumns.splice(0, 0, {
        key: 'request_destination',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.request_destination`), 
        sortable: true,
      })
    }  

    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.registerModule(
        this.ELEMENT_APP_STORE_MODULE_NAME,
        ElementStoreModule
      )

    this.fetchElements(this.type)
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    getNextConsult(item) {
      if (item.order && item.order.next_consult) {
        return item.order.next_consult.value
      }
      if (item.next_consult) {
        return item.next_consult.value
      }
      return ''
    },
    getAuthNumber(authNumber) {
      if (!authNumber) return '';
      return authNumber.replace('LAB-', '');
    },
    getUrl(item) {
      if (!item || !item.path) {
        return ''
      }
      let path = item.path.split('/')
      path = path[path.length - 1]
      return `${process.env.VUE_APP_BASE_API}/api/v1/digital_file/digital_file/record/${path}`
    },
    formatDate(field) {
      return field ? moment(field, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''
    },
    async fetch_labs() {
      return store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_labs`, {})
        .then((response) => {
          const list = response.data.items
          list.unshift({
            id: null,
            name: this.$t(
              `${this.RESOURCES_ELEMENT_NAME}.object.select_lab_default`
            ),
          })
          return list
        })
        .catch((error) => {
          if (error.response.status === 404) {
            return undefined
          }
        })
    },
    fetchElements(type) {
      store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchCase`, { id: this.case_id })
        .then(caseResponse => {
          if (caseResponse && caseResponse.data) {
            const caseData = caseResponse.data;
            
            return store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_lab_tests`, {
              queryParams: {
                perPage: this.perPage,
                page: this.currentPage,
                type,
                case: this.case_id,
                follow_type: this.followtype,
              },
            }).then(labTestsResponse => {
              this.items = labTestsResponse.data.items.map(item => ({
                ...item,
                case: caseData 
              }));
              this.total = labTestsResponse.data.total;
            });
          }
        })
        .catch(error => {
          console.error("Error al cargar los datos del caso o los tests de laboratorio", error);
        });
    },
    getUrl(item) {
      if (!item || !item.path) {
        return ''
      }
      let path = item.path.split(/[\/,\\]+/)
      path = path[path.length - 1]
      return `${process.env.VUE_APP_BASE_API}/api/v1/digital_file/digital_file/record/${path}`
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
